@import "../node_modules/ant-design-vue/dist/reset.css";

*{
  box-sizing: border-box;
}
html,body{
  margin: 0;
  padding: 0;
}
a{
  color: unset;
  text-decoration: unset;
}
.page{
  position: absolute;
  width: 100vw;
  height: 100vh;
  background: url("images/img.jpeg");
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  .container{
    padding: 40px;
    background: rgba(0,0,0,0.3);
  }
  .footer{
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 10px;
    position: absolute;
    bottom: 0;
    color: white;
    a{
      color: #2e8ae5;
    }
  }
}